import ApiRebyu from "../config/ApiRebyu";
import api_url from '../../utils/api.url';

export default  {

     /**
     * Funcion que permite obtener las lineas de negocio
     * @returns 
     */
      async getBusinessLine() {
        try {
            const url = api_url.APP_REBYU_BUSINESS_LINE_GET;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los valores de la linea de negicio
     * @param {*} id_business_line 
     * @returns 
     */
    async getBusinessLineValue(id_business_line) {
        try {
            const url = api_url.APP_REBYU_BUSINESS_LINE_VALUE_GET + id_business_line;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },


}