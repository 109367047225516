<template>
<div class="sm:px-1 md:px-10 sm:py-1 md:py-5 h-auto bg-gray-50">
  <v-card elevation="1">
       <v-loading :is_loading="isloading" />
         <v-card-text>
            <div class="grid gap-y-5">
               
                    <div class="relative h-80">
                        <div class="static">
                            <div class="h-60 md:h-64 w-full bg-gray-300 rounded-tl-md rounded-tr-md absolute bg-cover bg-center" :style="`background-image: url('`+(images.CoverPhotoFile ? images.CoverPhotoFile : entity.CoverPhoto)+`')`">
                                <div class="flex justify-between items-center">
                                    <div class="h-60  w-10 md:w-20 backdrop-filter backdrop-blur-sm bg-white/30 md:backdrop-blur-lg"></div>
                                    <svg v-if="!images.CoverPhotoFile && !entity.CoverPhoto" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <div class="sm:h-60 md:h-64  w-10 md:w-20 backdrop-filter backdrop-blur-sm bg-white/30 md:backdrop-blur-lg"></div>
                                    <div class="absolute bottom-2 right-1 text-gray-400 font-sans text-xs tracking-wider font-bold" v-if="!images.CoverPhotoFile && !entity.CoverPhoto">1300x250</div>
                                </div>
                                 <v-dialog 
                                    v-model="iscoverphoto"
                                    persistent
                                    max-width="1300px"
                                    >
                                    <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-12 h-12 rounded-full bg-gray-900  absolute top-2 right-2 opacity-80">
                                            <div class="h-12 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                    <v-cropper :height="250" :width="1300" :open="iscoverphoto" v-on:close="iscoverphoto = false" v-on:save="onUploadedCover" />
                                 </v-dialog>
                            </div>
                            <div class="h-40 w-40 rounded-full bg-gray-400 relative top-40 left-1/4 md:left-10 bg-cover bg-center" :style="`background-image: url('`+(images.ProfilePictureFile ? images.ProfilePictureFile : entity.ProfilePicture)+`')`">
                                   <v-dialog 
                                    v-model="isprofilephoto"
                                    persistent
                                    max-width="700px"
                                    >
                                    <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-12 h-12 rounded-full bg-gray-900  absolute bottom-1 -right-0 opacity-80">
                                            
                                            <div class="h-12 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                     <v-cropper :height="500" :width="500" :open="isprofilephoto" v-on:close="isprofilephoto = false" v-on:save="onUploadedProfile" />
                                </v-dialog>
                                <div class="w-full h-full flex items-center justify-center">
                                   <div class="absolute bottom-5 text-gray-500 font-sans text-xs tracking-wider font-bold z-50" v-if="!images.ProfilePictureFile && !entity.ProfilePicture">500x500</div>  
                                    <svg v-if="!images.ProfilePictureFile && !entity.ProfilePicture" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                <small class="text-error" v-show="($v.images.ProfilePictureFile.$anyDirty && !$v.images.ProfilePictureFile.required) || ($v.images.CoverPhotoFile.$anyDirty && !$v.images.CoverPhotoFile.required)">
                    * Las fotos son requeridas</small>
 
            
            <div class="sm:px-1 md:px-10">
             <div>
                <div>
                    <small class="text-gray-500 tracking-wide">Informacion de la Entidad</small>
                </div>
                <v-divider></v-divider>
               </div>
               <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">
                    <div>
                            <label class="text-base">Nombre</label>
                            <input :disabled="isloading" v-model="$v.entity.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                            <small class="text-error" v-show="$v.entity.name.$anyDirty && !$v.entity.name.required">* El nombre es requerido</small>
                            <div class="mt-2">
                            <div class="flex gap-2 item-center">
                               <label class="text-base">Identificador </label>
                               <v-tooltip top  color="pink lighten-1">
                                   <template v-slot:activator="{ on, attrs }">
                                    <svg v-bind="attrs" v-on="on" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                   </template>
                                    <span>No agregar espacios en blanco</span>
                               </v-tooltip>
                            </div>
                             <input  @change="onChangeTag(entity.tagname)" :disabled="isloading || isloadtagname" v-model="$v.entity.tagname.$model" class="input-full-fucsia"  placeholder="Ingrese el identificador, ejemplo MyStore" />
                             <small class="text-error" v-show="$v.entity.tagname.$anyDirty && !$v.entity.tagname.required">* El nombre es requerido</small>
                             <small class="text-error" v-show="tanamegexist">* El nombre ya esta ocupado</small>
                            </div>
                    </div>
                   <div>
                          <label class="text-base">Descripción</label>
                          <textarea :disabled="isloading" v-model="$v.entity.description.$model" class="input-full-fucsia" rows="5" placeholder="Ingrese la descripción de la empresa"></textarea>
                          <div class="flex justify-between">
                                <div>         
                                    <small class="text-error" v-show="$v.entity.description.$anyDirty && !$v.entity.description.required">* La descripción es requerida</small>
                                    <small class="text-error" v-show="$v.entity.description.$anyDirty && !$v.entity.description.minLength">* La descripción debe contener un mínimo de {{$v.entity.description.$params.minLength.min}} caracteres</small>
                                    <small class="text-error" v-show="$v.entity.description.$anyDirty && !$v.entity.description.maxLength">* La descripción debe contener un máximo de {{$v.entity.description.$params.maxLength.max}} caracteres</small>
                                </div>
                                <div>
                                    <small>{{ entity.description.length +' / '+$v.entity.description.$params.maxLength.max }}</small>
                                </div>
                            </div>
                    </div>
                </div>
                             <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">

                     <div class="flex justify-between items-center">
                        <label class="text-base font-sans text-gray-100">Icono</label>
                            <div class="flex justify-between items-center">
                                <div>
                                    <span class="text-base font-sans text-gray-700">Selecciona el icono que representa tu entidad</span>
                                </div>
                                <div>
                                    <v-dialog 
                                        v-model="isiconphoto"
                                        persistent
                                        max-width="400px"
                                        >
                                <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-20 h-20 border-2 border-dotted border-control-dark-border flex justify-center items-center rounded-md bg-cover bg-center"  :style="`background-image: url('`+(images.iconFile ? images.iconFile : entity.icon)+`')`">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        </div>
                                </template>
                                    <v-cropper :height="200" :width="200" :open="isiconphoto" v-on:close="isiconphoto = false" v-on:save="onUploadIcon" />
                                </v-dialog>
                                </div>
                            </div>
                      </div>
                     
                        <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                            <div>
                            <label class="text-base font-sans font-semibold">Linea de Negocio</label>
                            <v-auto-complete
                                v-model="$v.entity.id_business_line.$model"
                                :vmodel="entity.id_business_line"
                                :values="businessline"
                                :vtext="'name'"
                                :vvalue="'id'"
                                :title="'name'"
                                :isdisabled="!businessline"
                                />
                                <small class="text-error" v-show="$v.entity.id_business_line.$anyDirty && !$v.entity.id_business_line.required">* La linea es requerida</small>
                            </div>
                            <div>
                                <label class="text-base font-sans font-semibold">Linea Especifica</label>
                                <v-auto-complete
                                    v-model="$v.entity.id_business_line_value.$model"
                                    :vmodel="entity.id_business_line_value"
                                    :values="businesslinevalue"
                                    :vtext="'name'"
                                    :vvalue="'id'"
                                    :title="'name'"
                                    :disabled="!businesslinevalue"
                                    />
                                    <small class="text-error" v-show="$v.entity.id_business_line_value.$anyDirty && !$v.entity.id_business_line_value.required">* La linea especifica es requerida</small>
                            </div>
                        </div>
                 </div>  

                <div>
                    <div>
                        <small class="text-gray-500 tracking-wide">Informacion de Contacto</small>
                    </div>
                    <v-divider></v-divider>
               </div>
               <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">
                <div>
                        <label class="text-base">Teléfono</label>
                        <input :disabled="isloading" v-model="$v.entity.telephone.$model" class="input-full-fucsia"  placeholder="Ingrese el teléfono" />
                        <small class="text-error" v-show="$v.entity.telephone.$anyDirty && !$v.entity.telephone.required">* El teléfono es requerido</small>
                </div>
                    <div>
                        <label class="text-base">WhatsApp</label>
                        <input :disabled="isloading" v-model="$v.entity.whatsapp.$model" class="input-full-fucsia"  placeholder="Ingrese el whatsapp" />
                        <small class="text-error" v-show="$v.entity.whatsapp.$anyDirty && !$v.entity.whatsapp.required">* El whatsapp es requerido</small>
                    </div>
                </div>
               <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">
                <div>
                        <label class="text-base">Correo</label>
                        <input :disabled="isloading" v-model="$v.entity.email.$model" class="input-full-fucsia"  placeholder="Ingrese el correo" />
                        <small class="text-error" v-show="$v.entity.email.$anyDirty && !$v.entity.email.required">* El correo es requerido</small>
                </div>
                    <div>
                        <label class="text-base">Sitio Web</label>
                        <input :disabled="isloading" v-model="$v.entity.web.$model" class="input-full-fucsia"  placeholder="Ingrese el sitio web" />
                        <small class="text-error" v-show="$v.entity.web.$anyDirty && !$v.entity.web.required">* El sitio web es requerido</small>
                    </div>
                </div>

                 <div>
                    <div>
                        <small class="text-gray-500 tracking-wide">Redes Sociales</small>
                    </div>
                    <v-divider></v-divider>
                 </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">
                    <div>
                            <label class="text-base">Facebook</label>
                            <input :disabled="isloading" v-model="$v.entity.facebook.$model" class="input-full-fucsia"  placeholder="Ingrese su direcció de Facebook" />
                            <small class="text-error" v-show="$v.entity.facebook.$anyDirty && !$v.entity.facebook.required">* El facebook es requerido</small>
                    </div>
                        <div>
                            <label class="text-base">Twitter</label>
                            <input :disabled="isloading" v-model="$v.entity.twitter.$model" class="input-full-fucsia"  placeholder="Ingrese su direcció de Twitter" />
                            <small class="text-error" v-show="$v.entity.twitter.$anyDirty && !$v.entity.twitter.required">* El twitter web es requerido</small>
                        </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-10 px-3 md:px-5 py-2">
                    <div>
                            <label class="text-base">Instagram</label>
                            <input :disabled="isloading" v-model="$v.entity.instagram.$model" class="input-full-fucsia"  placeholder="Ingrese su direcció de Instagram" />
                            <small class="text-error" v-show="$v.entity.instagram.$anyDirty && !$v.entity.instagram.required">* El instagram es requerido</small>
                    </div>
                </div>

                </div>                 
             </div>
         </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button :disabled="isloading"
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
              @click="back">Cancelar</button>
             <button @click="save" :disabled="isloading"
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
               >Guardar</button>
          </v-card-actions>
  </v-card>
  </div>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
 import globalMixin from '@/mixins/globalMixin';
import Entity from '../../models/entity/entity.model';
import { validationMixin } from 'vuelidate';
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import businessLineService from '../../services/catalogs/businessline.service';
import cloudStorageService from '../../services/firebase/cloudstorage.service';
import file from '../../utils/file/file';
import commoncontext from '../../utils/common/firebasecontext';


export default {
    mixins: [validationMixin, toastMixin, globalMixin ],
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            iscoverphoto: false,
            isprofilephoto: false,
            isupdatecover: false,
            isupdateprofile: false,
            isupdateicon: false,
            entity: new Entity(),
            tanamegexist: false,
            isloadtagname: false,
            current_tagname: null,
            isiconphoto: false,
            businessline: [],
            businesslinevalue: [],
            images: {
                ProfilePictureFile: '',
                CoverPhotoFile: '',
                iconFile: ''

            }
        }
    },
    validations: {
         entity: {
             name : {
                 required
             },
             telephone : {
                 required
             },
             whatsapp : {
                 required
             },
             description : {
                 required,
                 minLength: minLength(10),
                maxLength: maxLength(5000)
             },
             email : {
                 required
             },
             web: {
                 required: false
             },
             facebook: {
                 required: false
             },
             twitter: {
                 required: false
             },
             instagram: {
                 required: false
             },
             
             tagname: {
                 required
             },
            id_business_line: {
                required
            },
             id_business_line_value: {
                 required
             }
         },
          images: {
            ProfilePictureFile: {
                 required: requiredIf(function() { 
                     return this.isupdateprofile ? true: false;
                 })
             },
             CoverPhotoFile: {
                  required: requiredIf(function() { 
                     return this.isupdatecover ? true: false;
                 })
             },
         }
    },
    mounted() {
        this.init();
    },
    methods: {

        init: async function() {
            this.isloading= true;
            await this.getEntity(this.id);
            await this.getBusinessLine();
            this.isloading= false;
        },
        getEntity: async function(id) {
            let response = await EntityService.getInfo(id);
            if(response.success) {
                this.entity = response.data;
                this.current_tagname = this.entity.tagname;
            }else {
                this.failure(response.message);
            }
        },

        getBusinessLine: async function() {
            let response = await businessLineService.getBusinessLine();
            if(response.success) {
                this.businessline = response.data;
            }
        },

        getBusinessLineValue: async function(id_business) {
            let response = await businessLineService.getBusinessLineValue(id_business);
            if(response.success) {
                this.businesslinevalue = response.data;
            }
        },

        save: async function() {

            this.$v.$touch();
            if(!this.$v.$anyError && !this.tanamegexist) {
                let confirm = await this.confirm('Actualizar Tienda', '¿Los datos son correctos?');
                if(confirm) {
                    
                    this.isloading = true;
                    
                    // FOTO DE PERFIL
                    if(this.images.ProfilePictureFile && this.isupdateprofile) {
                        let profFileBlob = await file.b64toBlob(this.images.ProfilePictureFile, 'image/jpeg');
                        const responseProfile = await cloudStorageService.uploadFile(commoncontext.ENTITY, profFileBlob, this.entity.profile_public_id);
                        this.entity.ProfilePicture = responseProfile.url;
                        this.entity.profile_public_id = responseProfile.public_id;
                        this.entity.profile_fullpath = responseProfile.fullPath;
                    }

                    //FOTO DE PORTADA
                    if(this.images.CoverPhotoFile && this.isupdatecover) {
                        let coverFileBlob = await file.b64toBlob(this.images.CoverPhotoFile, 'image/jpeg');
                        const responseCover = await cloudStorageService.uploadFile(commoncontext.ENTITY, coverFileBlob, this.entity.cover_public_id);
                        this.entity.CoverPhoto = responseCover.url;
                        this.entity.cover_public_id = responseCover.public_id;
                        this.entity.cover_fullpath = responseCover.fullPath;
                    }

                    // ICONO
                    if(this.images.iconFile && this.isupdateicon) {
                        let iconFileBlob = await file.b64toBlob(this.images.iconFile, 'image/png');
                        const responseIcon = await cloudStorageService.uploadFile(commoncontext.ICON, iconFileBlob, this.entity.icon_public_id);
                        this.entity.icon = responseIcon.url;
                        this.entity.icon_public_id = responseIcon.public_id;
                        this.entity.icon_fullpath = responseIcon.fullPath;
                    }



                    let response = await EntityService.update(this.id, this.entity)
                    this.isloading = false;
                    if(response.success) {
                        this.success(response.message);
                        this.back();
                    }else {
                        this.isloading = false;
                        this.failure(response.message);
                    } 
                }
            }
        },
        back() {
            this.$router.go(-1);
        },
        onUploadedProfile: function (data) {
            this.isprofilephoto = false;
            this.images.ProfilePictureFile = data;
            this.isupdateprofile = true;
        },
        onUploadedCover: function (data) {
            this.iscoverphoto = false
            this.images.CoverPhotoFile = data;
            this.isupdatecover = true;
        },
        onUploadIcon: function (data) {
            this.isiconphoto = false
            this.images.iconFile = data;
            this.isupdateicon = true;
        },
        onChangeTag: function (tag) {
            if(tag !=  this.current_tagname) {
                this.tagNameValidator(tag);
            }
        },
        tagNameValidator: async function (tag) {
            this.tanamegexist = false;
            this.isloadtagname = true;
            let response = await EntityService.tagNameValidator(tag);
            this.isloadtagname = false;
            if(response.success) {
                this.tanamegexist = response.data.exist ? true : false;
            }
        }
    },
     watch: {
        'entity.id_business_line': async function () {
            if(this.entity.id_business_line) {
                await this.getBusinessLineValue(this.entity.id_business_line);
            }else {
                 this.businesslinevalue = [];
                 this.entity.id_business_line_value = '';
            }
        }
    }
}
</script>

<style>

</style>