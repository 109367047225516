export default class Entity {
    constructor(
        name,
        ProfilePicture,
        ProfilePicture_Public_id,
        CoverPhoto,
        CoverPhoto_Public_id,
        telephone,
        whatsapp,
        description,
        email,
        web,
        facebook,
        twitter,
        instagram,
        tagname,
        icon,
        icon_public_id,
        profile_fullpath,
        cover_fullpath,
        icon_fullpath,
        id_business_line,
        id_business_line_value
        
    ) {
        this.name = name ? name : '',
        this.ProfilePicture = ProfilePicture ? ProfilePicture : '',
        this.ProfilePicture_Public_id = ProfilePicture_Public_id ? ProfilePicture_Public_id : '',
        this.telephone = telephone ? telephone : '',
        this.whatsapp = whatsapp ? whatsapp : '',
        this.description = description ? description : '',
        this.email = email ? email : ''
        this.web = web ? web : '',
        this.facebook = facebook ? facebook : '',
        this.twitter = twitter ? twitter : '',
        this.instagram = instagram ? instagram : '',
        this.CoverPhoto = CoverPhoto ? CoverPhoto : '',
        this.CoverPhoto_Public_id = CoverPhoto_Public_id ? CoverPhoto_Public_id : '',
        this.tagname = tagname ? tagname : '',
        this.icon = icon ? icon : '',
        this.icon_public_id = icon_public_id ? icon_public_id : '',
        this.profile_fullpath = profile_fullpath ? profile_fullpath : '',
        this.cover_fullpath =  cover_fullpath ? cover_fullpath : '',
        this.icon_fullpath =  icon_fullpath ? icon_fullpath : '',
        this.id_business_line =  id_business_line ? id_business_line : '',
        this.id_business_line_value =  id_business_line_value ? id_business_line_value : ''
        
    }
}